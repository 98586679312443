@use '../includes' as *;

.tab_container {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  padding-block-start: $g12;
  overflow: hidden;
  border-block-start: $slate-border;

  @include breakpoint($md) {
    padding-block-start: $g16;
  }
}

// row of tabs
.tabs {
  @include horizontal-scroll-with-hidden-scrollbar;

  display: flex;
  position: relative;
  flex-basis: 100%;
  justify-content: left;
  gap: $g16;
  // not sure why this is needed
  height: 30px;
  padding-inline-start: var(--spacing-inline);
  overflow-y: hidden;

  @include breakpoint($sm) {
    justify-content: center;
    gap: $g32;
    height: auto;
    padding-inline: var(--spacing-inline);
  }

  @include breakpoint($md) {
    gap: $g64;
    padding-block-end: $g32;
  }

  @include breakpoint($xl) {
    gap: $g80;
  }

  &::after {
    // position: sticky elements don't work with explicit widths - they only expand to the size of
    // their content. So here we are inserting a bunch of non-breaking spaces to force the width.
    content: '\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0';
    position: sticky;
    inset: 0 calc(-1 * var(--spacing-inline)) 0 auto;
    height: 42px;
    background-image: linear-gradient(
      to right,
      rgba($midnight, 0),
      $midnight 50%
    );
    pointer-events: none;
  }

  // if we're on a modal page, which has a different background color,
  // change this gradient to match
  body:has([class*='modal_page']) & {
    &::after {
      background-image: linear-gradient(
        to right,
        rgba($paynes-gray, 0),
        $paynes-gray 50%
      );
    }
  }

  @include breakpoint($xs) {
    &::after {
      display: none;
    }
  }
}

.tab_sections {
  flex-basis: 100%;
}

.tab_panel {
  padding-block: $g24;
  padding-inline: var(--spacing-inline);

  @include breakpoint($md) {
    padding-block: $g32;
  }
}
