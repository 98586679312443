@use '../includes' as *;

.learning_media_badge {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: $g12;
  width: 100%;
  height: max-content;
  padding-block: $g24;
  padding-inline: $g40;
  border-radius: $radius-med;
  background-color: $light-blue;
  color: $white;
  font-size: $f12;
  font-weight: $bold;
  line-height: 1.5;
  text-align: center;

  .learning_media_badge__copy_long {
    display: none;
  }

  .learning_media_badge__copy_short {
    display: inline-block;
  }

  @include breakpoint($sm) {
    flex-direction: row;
    height: $g64;
    padding-inline: $g80;
    font-size: $f14;

    .learning_media_badge__copy_long {
      display: inline-block;
    }

    .learning_media_badge__copy_short {
      display: none;
    }

    .learning_media_badge__button {
      font-size: $f14;
    }
  }

  @include breakpoint($md) {
    gap: $g24;
    padding-inline: $g96;
    font-size: $f16;

    .learning_media_badge__button {
      font-size: $f16;
    }
  }

  @include breakpoint($lg) {
    gap: $g40;
    height: $g64;
    padding-inline: $g160;
    font-size: $f18;

    .learning_media_badge__button {
      font-size: $f18;
    }
  }

  svg {
    width: clamp(98px, 25vw, 117px);
    height: auto;
  }
}

.learning_media_badge__button {
  font-size: $f12;
  white-space: nowrap;
}
