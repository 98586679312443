@use '../includes' as *;

.modal_page {
  background-color: $paynes-gray;

  @include breakpoint($md) {
    margin-block: calc(var(--spacing-block) / 2);
    margin-inline: var(--spacing-inline);
    overflow: hidden;
    border-radius: 20px;
  }

  // funky way to get the hightlight & shop components to be full width
  // this is very brittle and could break easily
  div[class*='about_tab'] {
    > div[class*='video_row'],
    > div[class*='highlight'],
    > section[class*='shop'] {
      width: calc(100% + var(--spacing-inline) * 2);
      margin-inline: calc(-1 * var(--spacing-inline));
    }
  }
}

@include bp-utility-nav-mouse-only {
  body {
    &:has(.modal_page.internal_navigation) {
      header {
        nav {
          position: absolute;
          transition: filter var(--duration) ease-in;
          background-color: transparent;
          filter: blur(10px);
        }

        &:hover,
        &:focus-within {
          nav {
            filter: blur(0);
          }
        }
      }
    }
  }
}
