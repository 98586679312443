@use '../includes' as *;

.tab_link {
  @include h4($pebble, $normal);

  display: inline-block;

  // margin prevents the focus outline from being blocked
  margin: 4px 4px 0;
  transition: font-weight 0.1s ease;
  white-space: nowrap;
  text-underline-offset: $g8;

  @include breakpoint($lg) {
    text-underline-offset: $g12;
  }

  &:hover,
  &:focus,
  &:active {
    color: $fog;
    text-decoration: none;
  }

  &.is_active {
    color: $white;
    font-weight: $bold;
    text-decoration: underline $teal;
    text-decoration-thickness: 3px;

    // Doesn't work with the variable $below-lg for some reason
    @include breakpoint($lg) {
      text-decoration-thickness: 4px;
    }
  }
}
